<template>
  <div>
    <v-spacer style="height: 68px;" />
    <!-- Body -->
    <v-container class="px-9 pt-0 pb-6 bigScreenForm">
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <h3 class="mb-4" v-text="$t('project.academy.calendar.attendanceTitle')" />
              <span v-text="$t('project.academy.calendar.attendanceSubtitle')" />
            </v-col>
          </v-row>
          <template v-if="formattedUsers">
            <v-row v-for="(user, i) in formattedUsers" :key="i" class="d-flex flex-row align-center justify-start mb-2 pl-2">
              <v-icon
                :color="icons[user.attending].color"
              >
                {{ icons[user.attending].icon }}
              </v-icon>
              <span class="ml-4" v-text="user.name" />
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'AttendanceList',
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    eventId: { type: String, default: null },
  },
  data() {
    return {
      formattedUsers: [],
      icons: {
        true: {
          icon: 'check',
          color: 'primary',
        },
        false: {
          icon: 'close',
          color: 'error',
        },
        null: {
          icon: 'horizontal_rule',
          color: 'inactive',
        },
      },
    }
  },
  computed: {
    ...mapGetters({ activity: 'activity/data' }),
  },
  watch: {
    activity: {
      immediate: true,
      async handler(data) {
        this.$store.commit('loader/show')
        const rawUsers = data?.attendanceByUserId
        this.formattedUsers = rawUsers && await Promise.all(Object.entries(rawUsers).map(async user => {
          const userData = await this.$store.dispatch('user/read', user[0])
          return {
            name: `${userData.firstName} ${userData.lastName}`,
            attending: user && user[1],
          }
        }))
        this.$store.commit('loader/hide')
      },
    },
  },
  async created() {
    this.$store.commit('loader/show')

    const { activity, organizationId, projectId, eventId } = this
    if (activity?.id !== eventId) {
      const entity = await this.$store.dispatch('activity/bind', { organizationId, projectId, activityId: eventId })
      if (!entity) this.$router.replace({ name: '404' })
    }

    this.$store.commit('loader/hide')
  },
}
</script>
